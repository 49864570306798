import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";


export default function S3() {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [marker, setMarker] = useState(null); // State to track pagination marker
  const [isLoading, setIsLoading] = useState(false);


  AWS.config.update({
    accessKeyId: "AKIAXU4O6HQ6VLZLFWFG",
    secretAccessKey: "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",
    region: "us-west-1",
  });
  // const fetchFiles = () => {
  //   const s3 = new AWS.S3();

  //   const params = {
  //     Bucket: "3dbreakout",
  //   };
  //   s3.listObjects(params, (err, data) => {
  //     if (err) {
  //       console.log(err, err.stack);
  //     } else {
  //       console.log(data);
  //       // const fileList = data.Contents.map((item) => {
  //       //   const key = item.Key;
  //       //   console.log(key)
  //       //   const type = key.split(".").pop();
  //       //   const url = s3.getSignedUrl("getObject", {
  //       //     Bucket: params.Bucket,
  //       //     Key: key,
  //       //     Expires: 3600, // URL expiration time in seconds (1 hour in this example)
  //       //   });
  //       //   return { key, type, url };
  //       // });
  //       const fileList = data.Contents.map((item) => {
  //         const key = item.Key;
  //         const type = key.endsWith("/") ? "Folder" : key.split(".").pop();
  //         const url = !key.endsWith("/")
  //           ? s3.getSignedUrl("getObject", {
  //               Bucket: params.Bucket,
  //               Key: key,
  //               Expires: 3600, // URL expiration time in seconds (1 hour in this example)
  //             })
  //           : null;
  //         return { key, type, url };
  //       });
  //       setFiles(fileList);
  //     }
  //   });
  // };
  const fetchFiles = (marker = null) => {
    const s3 = new AWS.S3();
  
    const params = {
      Bucket: "3dbreakout",
      Marker: marker, // Marker indicates the starting point for the next list of objects
      MaxKeys: 10, // MaxKeys specifies the maximum number of objects to return per request
    };
  
    s3.listObjects(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log(data);
        const fileList = data.Contents.map((item) => {
          const key = item.Key;
          const type = key.endsWith("/") ? "Folder" : key.split(".").pop();
          const url = !key.endsWith("/")
            ? s3.getSignedUrl("getObject", {
                Bucket: params.Bucket,
                Key: key,
                Expires: 3600, // URL expiration time in seconds (1 hour in this example)
              })
            : null;
          return { key, type, url };
        });
        setFiles((prevFiles) => [...prevFiles, ...fileList]);
  
        // If there are more files to fetch, recursively call fetchFiles with the marker
        if (data.IsTruncated) {
          fetchFiles(data.NextMarker);
        }
      }
    });
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleRefresh = () => {
    fetchFiles();
  };
  const handleDownload = (url) => {
    // Perform download action (e.g., open the URL in a new tab/window)
    window.open(url, "_blank");
  };

  const handleDelete = (key) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: "3dbreakout",
      Key: key,
    };
    s3.deleteObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        console.log("File deleted successfully:", key);
        // Refresh the file list after deletion
        fetchFiles();
      }
    });
  };

  // const handleUpload = async () => {
  //   try {
  //     setUploading(true);
  //     const result = new AWS.S3();
  //     result.uploadFile(selectedFile).then(data => console.log(data)).catch(error =>console.log(error))
  //     // const result = await AWS.s3.put(selectedFile.name, selectedFile, {
  //     //   contentType: selectedFile.type,
  //     // });
  //     console.log("Uploaded file:", result);
  //     // Refresh the file list after upload
  //     fetchFiles();
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   } finally {
  //     setUploading(false);
  //   }
  // };
  const handleUpload = async () => {
    try {
      setUploading(true);
  
      const s3 = new AWS.S3();
      const params = {
        Bucket: "3dbreakout",
        Key: selectedFile.name, // Use the file name as the key
        Body: selectedFile,
      };
  
      await s3.upload(params).promise(); // Use upload method and wait for it to complete
  
      console.log("Uploaded file:", selectedFile.name);
      // Refresh the file list after upload
      const url = `https://s3-${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;

      // Perform actions with the URL, such as saving it to a database
      console.log("URL of uploaded file:", url);
      fetchFiles();
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploading(false);
    }
  };
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const loadMore = () => {
    if (!isLoading) {
      fetchFiles();
    }
  };
  return (
    <div>
    <h2>Files in S3 Bucket</h2>
    <input type="file" onChange={handleFileChange} />
    <button onClick={handleUpload} disabled={!selectedFile || uploading}>
      Upload
    </button>
     <div>
      {files.map((file, index) => (
        <div key={index}>
          <span>{file.key}</span>
          <span className="bg-red-400">{file.type.toUpperCase()}</span>
          <button onClick={() => handleDownload(file.url)}>Download</button>
          <button onClick={() => handleDelete(file.key)}>Delete</button>
        </div>
      ))}
    </div>
    {marker && (
      <div>
        <button onClick={loadMore} disabled={isLoading}>
          {isLoading ? "Loading..." : "Load More"}
        </button>
      </div>
    )}
  </div>
);
}
