import React, { useContext } from "react";
import DataTable from "react-data-table-component";
import { ModeContext } from "../App";
import primaryStyles, { primaryStylesDark } from "../utilities/CustomStyles";

export default function MyDataTable(props) {
  const { dark } = useContext(ModeContext);
  
  // Ensure that each column has the sortable property if you want sorting on them
  const columns = props.columns.map(col => ({
    ...col,
    sortable: col.sortable !== false // Enable sorting unless explicitly set to false
  }));

  return (
    <DataTable
      theme="myTheme"
      customStyles={dark ? primaryStylesDark : primaryStyles}
      columns={columns}
      data={props.data}
      pagination
      sort
      paginationPerPage={50}
    />
  );
}
